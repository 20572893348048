import "@mantine/core/styles.css"
import "@mantine/notifications/styles.css"
import "@mantine/dates/styles.css"
import "@mantine/spotlight/styles.css"
import "@mantine/charts/styles.css"
import "../styles/global.css"
import "../utils/extendDayjs"

import { createTheme, MantineProvider } from "@mantine/core"
import { DatesProvider } from "@mantine/dates"
import { ModalsProvider } from "@mantine/modals"
import { Notifications } from "@mantine/notifications"
import { Decimal } from "decimal.js"
import { NextPage } from "next"
import { type AppProps } from "next/app"
import { Inter } from "next/font/google"
import Head from "next/head"
import { type Session } from "next-auth"
import { SessionProvider } from "next-auth/react"
import { NextAdapter } from "next-query-params"
import { NuqsAdapter } from "nuqs/adapters/next/pages"
import { ReactElement, ReactNode } from "react"
import SuperJSON from "superjson"
import { QueryParamProvider } from "use-query-params"

import { AppConfigProvider } from "../components/AppConfigProvider"
import { api } from "../utils/api"

// If loading a variable font, you don't need to specify the font weight
export const nextFontInter = Inter({
  weight: ["300", "400", "500", "600", "700", "800", "900"],
  style: ["normal"],
  subsets: ["latin"],
  display: "swap",
})

const theme = createTheme({
  fontFamily: nextFontInter.style.fontFamily,
  headings: { fontFamily: nextFontInter.style.fontFamily },
  primaryColor: "cyan",
  cursorType: "pointer",
})

SuperJSON.registerCustom<Decimal, string>(
  {
    isApplicable: (v): v is Decimal => Decimal.isDecimal(v),
    serialize: (v) => v.toJSON(),
    deserialize: (v) => new Decimal(v),
  },
  "decimal.js",
)

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement, props: P) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
  session: Session | null
}

function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)

  return (
    <>
      <NuqsAdapter>
        <Head>
          <title>Proteus Admin</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
          <link rel="icon" href="/favicon.ico" />
        </Head>

        <MantineProvider theme={theme}>
          <AppConfigProvider>
            <SessionProvider session={session}>
              <QueryParamProvider adapter={NextAdapter}>
                <ModalsProvider>
                  <Notifications />

                  <DatesProvider settings={{ locale: "en", consistentWeeks: true }}>
                    {getLayout(<Component {...pageProps} />, pageProps)}
                  </DatesProvider>
                </ModalsProvider>
              </QueryParamProvider>
            </SessionProvider>
          </AppConfigProvider>
        </MantineProvider>
      </NuqsAdapter>
    </>
  )
}

export default api.withTRPC(App)
